#nav-main{
    margin-bottom: 3%;
}

#nav-main ul{
    position: fixed;
    top: 0;
    list-style-type: none;
    overflow: hidden;
    border-top: 3px solid black;
    border-bottom: 1px solid black;
    text-align: center;
    z-index: 1;
    list-style-type: none;
    margin: 0;
    padding-left: 30px;
    padding-right: 30px;
    overflow: hidden;
    background-color: #228B22;
    width: 100vw;
    

}
#nav-main li{
    list-style: none;
    display: inline-block;
}
.nav-link-main{
    display: inline-block;
    color: #00ff33;
    text-shadow: 1px 1px 2px #000000;
    font-weight: bold;
    text-align: center;
    padding: 7px  16px;
    text-decoration: none;
    font-size: 20px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.nav-link-main:hover{
    color: silver;
}