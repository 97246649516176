body{
    background-image: url(../../Components/Photo/1000_F_802950566_SaDL90mjawxwbs0fFjdFFZyCBOwSJqSA.jpg);
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
}
.container{
    border-radius: 30px;
    background-color: #00ff44d4;
    text-align: center;
    padding: 10px 20px 30px 20px;
    margin: 10% 20% 40% 20%;
    border: 1px solid black;
    box-shadow: 10px 10px 10px #000000;
}

h1{
    font-family: Helvetica, Arial, serif;
    color: rgb(0, 0, 0);
    font-size: 50px;
    text-shadow: 1px 1px #8ec3eb ;
}

p{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: whitesmoke;
    text-shadow: 1px 1px black ;
}

.label{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: bold;
}

input{
    margin-bottom: 10px;
}

#output {
    display: none;
}

#resultData{
    font-family:'Times New Roman', Times, serif;
    color: whitesmoke;
    font-size: 20px;
    margin-bottom: 10px;
}
 ul{
    list-style-type: none;
 }